import React from 'react';
import useTotalItems from '../hooks/useTotalItems';

export const ItemsContext = React.createContext({});

export const ItemsContextProvider = ({ children }) => {
	const { totalItems, totalItemsAmout } = useTotalItems();

	const contextValue = { totalItems, totalItemsAmout };

	return (
		<ItemsContext.Provider value={contextValue}>
			{children}
		</ItemsContext.Provider>
	);
};