import { Alert, Box, Button, Grid, Snackbar, TextField, Typography } from '@mui/material';
import React from 'react';
import validator from 'validator';

const Form = ({ formData, setFormData, disabled, setDisabled, handlePreview, notification }) => {
	const handleChange = React.useCallback((event) => {
		const { name, value } = event.currentTarget;
		let error = false;

		if (name === 'name' || name === 'message') {
			if (validator.isEmpty(value) || !value.trim()) {
				error = true;
			}
		} else if (name === 'email') {
			if (!validator.isEmail(value) || !value.trim()) {
				error = true;
			}
		}

		setFormData((prevState) => ({
			...prevState,
			[name]: {
				...prevState[name],
				value,
				error
			}
		}));
	}, [setFormData]);

	// If 'formFields' changes, check if one of the fields is having an error/empty value, if so, disable preview button
	React.useEffect(() => {
		const preventSubmit = Object.values(formData).some((item) => item.error || !item.value);

		setDisabled(preventSubmit);
	}, [formData, setDisabled]);

	return (
		<>
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={notification.open} autoHideDuration={5000} onClose={notification.onClose}>
				<Alert severity="success" sx={{ width: '100%' }}>
					Nachricht erfolgreich übermittel. Erwarte Freischaltung!
				</Alert>
			</Snackbar>

			<Grid container rowSpacing={2.5}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Typography variant="h5" component="h2">
						Erstelle einen Eintrag:
					</Typography>
				</Grid>

				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Box
						component="form"
						noValidate
						autoComplete="off"
					>
						<Grid container rowSpacing={2} columnSpacing={2} sx={{ alignItems: 'center' }}>
							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									fullWidth={true}
									id="outlined-basic"
									{...formData.name}
									onChange={handleChange}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
								<TextField
									fullWidth={true}
									id="outlined-basic"
									{...formData.email}
									onChange={handleChange}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<TextField
									fullWidth={true}
									multiline
									id="outlined-multiline-static"
									{...formData.message}
									onChange={handleChange}

									rows={6}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container rowSpacing={1} columnSpacing={1} sx={{ justifyContent: 'flex-end' }}>
									<Grid item xs={12} sm={12} md="auto" lg="auto" xl="auto">
										<Button variant="contained" sx={{ display: 'flex', width: '100%' }} onClick={handlePreview} disabled={disabled}>Vorschau</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default Form;