import { db } from "./firebase";
import { collection, doc, addDoc, getDoc, updateDoc, deleteDoc, query, getDocs } from "firebase/firestore";

const defaultCollectionName = 'guestbook';

export const createDocument = async ({ collectionName = defaultCollectionName, data = {} } = {}) => {
	if (!Object.entries(data).length) {
		throw Error('No "data" provided for createDocument.');
	} else if (!collectionName) {
		throw Error('No "collectionName" provided for createDocument. You also can define an env var "REACT_APP_FIRESTORE_COLLECTION_NAME" to define the collection name.');
	}

	data = {
		created: Date.now(),
		...data
	}

	const document = await addDoc(collection(db, collectionName), data);

	return {
		document,
		documentData: {
			id: document.id,
			...data
		}
	};
};

export const getDocument = async ({ collectionName = defaultCollectionName, id } = {}) => {
	if (!collectionName) {
		throw Error('No "collectionName" provided for getDocument. You also can define an env var "REACT_APP_FIRESTORE_COLLECTION_NAME" to define the collection name.');
	} else if (!id) {
		throw Error('No "id" provided for updateDocument.');
	}

	const docRef = doc(
		db,
		collectionName,
		id
	);

	const document = await getDoc(docRef);

	return {
		docRef,
		docData: document.data()
	};
};

export const updateDocument = async ({ collectionName = defaultCollectionName, id, data = {} } = {}) => {
	if (!Object.entries(data).length) {
		throw Error('No "data" provided for updateDocument.');
	} else if (!collectionName) {
		throw Error('No "collectionName" provided for updateDocument. You also can define an env var "REACT_APP_FIRESTORE_COLLECTION_NAME" to define the collection name.');
	} else if (!id) {
		throw Error('No "id" provided for updateDocument.');
	}

	const { docData, docRef } = await getDocument({
		collectionName,
		id
	});

	await updateDoc(docRef, {
		updated: Date.now(),
		...data
	});

	return {
		documentBeforeUpdate: docData
	}
};

export const deleteDocument = async ({ collectionName = defaultCollectionName, id } = {}) => {
	if (!collectionName) {
		throw Error('No "collectionName" provided for deleteDocument. You also can define an env var "REACT_APP_FIRESTORE_COLLECTION_NAME" to define the collection name.');
	} else if (!id) {
		throw Error('No "id" provided for updateDocument.');
	}

	const { docData, docRef } = await getDocument({
		collectionName,
		id
	});

	await deleteDoc(docRef);

	return {
		documentBeforeUpdate: docData
	}
};

// export const readAndWatchDocuments = ({ collectionName = defaultCollectionName, constraints = [] } = {}) => {
// 	if (!collectionName) {
// 		throw Error('No "collectionName" provided for readAndWatchDocuments. You also can define an env var "REACT_APP_FIRESTORE_COLLECTION_NAME" to define the collection name.');
// 	}

// 	const documentsQuery = query(
// 		collection(db, collectionName),
// 		...constraints
// 	);

// 	let documents = [];

// 	onSnapshot(
// 		documentsQuery,
// 		(querySnapshot) => {
// 			documents = querySnapshot.docs.map((doc) => ({
// 				id: doc.id,
// 				...doc.data(),
// 			}));
// 		},
// 		(error) => {
// 			throw new Error(error.message);
// 		}
// 	);

// 	return documents.map((item, index) => ({

// 		iteration: this.items.length - index,
// 		...item
// 	}));
// };

export const readDocuments = async ({ collectionName = defaultCollectionName, constraints = [] } = {}) => {
	if (!collectionName) {
		throw Error('No "collectionName" provided for readAndWatchDocuments. You also can define an env var "REACT_APP_FIRESTORE_COLLECTION_NAME" to define the collection name.');
	}

	const documentsQuery = query(
		collection(db, collectionName),
		...constraints
	);

	const documents = await getDocs(documentsQuery);

	return {
		documentsQuery,
		documents: documents.docs.map((document) => ({
			id: document.id,
			...document.data()
		})),
		totalDocuments: documents.docs.length
	};
};

export const createExampleDocuments = ({ collectionName = defaultCollectionName, amount = 20, locale } = {}) => {
	if (!collectionName) {
		throw Error('No "collectionName" provided for createExampleDocuments. You also can define an env var "REACT_APP_FIRESTORE_COLLECTION_NAME" to define the collection name.');
	}

	const longText = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.';

	for (let i = 1; amount >= i; i++) {
		createDocument({
			collectionName,
			data: {
				index: i,
				approved: true,
				author: {
					email: "s.trarbach@zw-systems.de",
					name: `Example Item ${locale.toUpperCase()} ${i}`,
				},
				language: locale,
				message: `Example Message for Item ${locale.toUpperCase()} ${i} ${(Math.floor(Math.random() * 2) === 1) ? longText : ''}`,
			},
		});
	}
};