import React from 'react';
import { Alert, Grid } from '@mui/material';
import Layout from '../../components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { createExampleDocuments } from '../../db/firestore';
import useCountdown from '../../hooks/useCountdown';

const DummyContent = () => {
	const { amount = 20, locale } = useParams();
	const navigate = useNavigate();
	const { timeLeft } = useCountdown(5, () => {
		navigate(`/${locale}`, {
			replace: true
		});
	}, 1000);

	React.useEffect(() => {
		createExampleDocuments({
			locale,
			amount: Number(amount)
		});

		setTimeout(() => {
			navigate(`/${locale}`, {
				replace: true
			});
		}, 5000);
	}, [amount, locale, navigate]);

	return (
		<Layout h1="Beispiel Einträge erstellen">
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Alert severity="info">
					Erstellen von {amount} dummy Einträgen erfolgreich.
				</Alert>
			</Grid>

			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Alert severity="info">
					Sie werden in {timeLeft} Sekunden weitergeleitet.
				</Alert>
			</Grid>
		</Layout>
	);
};

export default DummyContent;
