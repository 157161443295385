import React from 'react';
import { ItemsContextProvider } from '../../contexts/itemsContext';

const PageWrapper = ({ component }) => {
	const Component = component;

	return (
		<ItemsContextProvider>
			{Component}
		</ItemsContextProvider>
	);
}

export default PageWrapper;
