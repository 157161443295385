import { Alert, Grid } from '@mui/material';
import React from 'react';

const NoItems = () => {
	return (
		<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
			<Alert severity="error" sx={{ display: 'flex', justifyContent: 'center' }}>
				Aktuell liegen keine Einträge vor.
			</Alert>
		</Grid>
	);
};

export default NoItems;