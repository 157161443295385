import React from 'react';

const NotFound = () => {
	return (
		<>
			NotFound
		</>
	);
};

export default NotFound;