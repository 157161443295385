import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Language from './components/Language';
import Approve from './pages/Approve';
import Delete from './pages/Delete';
import DummyContent from './pages/DummyContent';
import Guestbook from './pages/Guestbook';
import NotFound from './pages/NotFound';
import PageWrapper from './components/PageWrapper';

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route index element={<Language />} />
				<Route path="/:locale" element={<PageWrapper component={<Guestbook />} />} />
				<Route path="/:locale/page/:pageID" element={<PageWrapper component={<Guestbook />} />} />
				<Route path="/eintrag/freischalten/:id" element={<Approve />} />
				<Route path="/eintrag/entfernen/:id" element={<Delete />} />
				<Route path="/:locale/beispiel-eintraege/:amount" element={<DummyContent />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
