import React from 'react';
import { useParams } from 'react-router-dom';
import config from '../config';

export function usePageItems({ items, ajax = false }) {
	const [pageItems, setPageItems] = React.useState(undefined);
	const { locale, pageID = 1 } = useParams();

	React.useEffect(() => {
		if (items === undefined) {
			setPageItems(undefined);

			return;
		}

		const page = Number(pageID);
		const maxPerPage = config.pages.load.maxPerPage;
		let startAt = 0;
		let endAt;

		if (page === 1) {
			endAt = maxPerPage - 1;
		} else {
			if (ajax) {
				endAt = (page * maxPerPage) - 1;
			} else {
				startAt = (page * maxPerPage) - maxPerPage;
				endAt = (startAt + maxPerPage) - 1;
			}
		}

		if (items) {
			setPageItems(items.filter((_, index) => index >= startAt && endAt >= index));
		}
	}, [locale, pageID, items, ajax]);

	return {
		pageItems
	}
}

export default usePageItems;
