import React from 'react';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useParams } from 'react-router-dom';

const StaticPagination = ({ total }) => {
	const { locale, pageID: page = 1 } = useParams();
	const navigate = useNavigate();

	const handlePageChange = (event, page) => {
		navigate(`/${locale}/page/${page}`);
	};

	return (
		<Pagination page={Number(page)} count={total} boundaryCount={2} variant="outlined" shape="rounded" sx={{ display: 'flex', justifyContent: 'center' }} onChange={handlePageChange} />
	);
};

export default StaticPagination;