import { Button, CardActions, Grid, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { createDocument } from '../../../db/firestore';
import { sendWelcomeEmail } from '../../../emails/emailjs';
import Item from '../../List/Item';
import { ItemsContext } from '../../../contexts/itemsContext';

const Preview = ({ formData, handlePreview, resetFormData, handleNotification }) => {
	const { locale: language } = useParams();
	const { totalItemsAmout } = React.useContext(ItemsContext);

	const [item] = React.useState({
		language,
		author: {
			name: formData.name.value,
			email: formData.email.value
		},
		message: formData.message.value,
		approved: false,
		created: Date.now(),
		index: totalItemsAmout + 1
	});

	const handleSubmit = () => {
		createDocument({
			data: item
		})
			.then((res) => {
				const {
					id,
					language,
					author: {
						name,
						email
					},
					message,
					index: iteration
				} = res.documentData;

				sendWelcomeEmail({
					id,
					language,
					author: {
						name,
						email
					},
					message,
					iteration
				});

				handlePreview();

				resetFormData();

				handleNotification();
			})
			.catch((error) => {
				throw new Error(error.message);
			});
	};

	return (
		<Grid container rowSpacing={2.5}>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Typography variant="h5" component="h2">
					Dein Eintrag:
				</Typography>
			</Grid>

			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Grid container rowSpacing={1} columnSpacing={1} sx={{ justifyContent: 'flex-end' }}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Item item={item}>
							<CardActions>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Grid container rowSpacing={1} columnSpacing={1} sx={{ justifyContent: 'flex-end' }}>
										<Grid item xs={6} sm={6} md="auto" lg="auto" xl="auto">
											<Button onClick={handlePreview}>
												Zurück
											</Button>
										</Grid>

										<Grid item xs={6} sm={6} md="auto" lg="auto" xl="auto">
											<Button variant="contained" sx={{ display: 'flex', width: '100%' }} onClick={handleSubmit}>
												Senden
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</CardActions>
						</Item>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Preview;