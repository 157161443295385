import React from 'react';
import FormSection from '../../components/FormSection';
import List from '../../components/List';
import Pagination from '../../components/Pagination';
import { Grid } from '@mui/material';
import Language from '../../components/Language';
import usePageItems from '../../hooks/usePageItems';
import Layout from '../../components/Layout';
import config from '../../config/index.json';
import { ItemsContext } from '../../contexts/itemsContext';

const Guestbook = () => {
	// TODO useConfig hook which returns only needed values
	const { pages: { load: { ajax } } } = config;
	const { totalItems, totalItemsAmout } = React.useContext(ItemsContext);
	const { pageItems } = usePageItems({
		items: totalItems,
		ajax
	});

	return (
		<Layout h1="Gästebuch">
			<Grid item sx={{ textAlign: 'center' }} xs={12} sm={12} md={12} lg={12} xl={12}>
				<Language />
			</Grid>

			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<FormSection totalItems={totalItemsAmout} />
			</Grid>

			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<List items={pageItems} />
			</Grid>

			{totalItemsAmout > 0 && totalItemsAmout > config.pages.load.maxPerPage && (
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Pagination total={totalItemsAmout} ajax={ajax} />
				</Grid>
			)}
		</Layout>
	);
};

export default Guestbook;