import { onSnapshot, orderBy, where } from 'firebase/firestore';
import React from 'react';
import { useParams } from 'react-router-dom';
import { readDocuments } from '../db/firestore';

export function useTotalItems() {
	const [totalItems, setTotalItems] = React.useState(undefined);
	const [totalItemsAmout, setTotalItemsAmout] = React.useState(0);
	const { locale } = useParams();

	console.log();

	React.useEffect(() => {
		// const q = query(collection(db, 'guestbook'), orderBy('created', 'desc'));

		// onSnapshot(q, {
		// 	next: (querySnapshot) => {
		// 		let snapShotData = querySnapshot.docs.map((doc) => ({
		// 			...doc.data() as ItemDataProps, // Maybe also null?
		// 			id: doc.id,
		// 		}));

		// 		setItems(snapShotData.map((doc, index) => ({
		// 			iteration: snapShotData.length - index,
		// 			...doc
		// 		})));
		// 	}
		// });

		// TODO Make "contrains" as a hook param
		readDocuments({
			constraints: [
				where("approved", "==", true),
				where("language", "==", locale),
				orderBy("created", "desc")
			],
		})
			.then((res) => {
				const { documentsQuery } = res;

				onSnapshot(
					documentsQuery,
					(querySnapshot) => {
						let documents = undefined;
						let snapShotData = querySnapshot.docs.map((doc, index) => ({
							...doc.data(),
							id: doc.id,
							iteration: querySnapshot.docs.length - index
						}));

						if (snapShotData.length) {
							documents = snapShotData;
						}

						setTotalItems(documents);

						setTotalItemsAmout(snapShotData.length);
					},
					(error) => {
						throw new Error(error.message);
					}
				);
			})
			.catch((error) => {
				throw new Error(error.message);
			});
	}, [locale]);

	return {
		totalItems,
		totalItemsAmout
	}
}

export default useTotalItems;
