import React from 'react';
import { Alert, Grid } from '@mui/material';
import Layout from '../../components/Layout';
import { updateDocument } from '../../db/firestore';
import Item from '../../components/List/Item';
import { useNavigate, useParams } from 'react-router-dom';
import useCountdown from '../../hooks/useCountdown';
import useStatus from '../../hooks/useStatus';
import Status from '../../components/Status';

const Delete = () => {
	const { status, setStatus } = useStatus();
	const [item, setItem] = React.useState(undefined);
	const { id } = useParams();
	const navigate = useNavigate();
	const { clearCountdown, timeLeft } = useCountdown(5, () => {
		navigate(`/${item.language}`, {
			replace: true
		});
	}, 1000);

	if (status === 'error') {
		clearCountdown();
	}

	React.useEffect(() => {
		updateDocument({
			id,
			data: {
				approved: true
			}
		})
			.then((res) => {
				if (res.documentBeforeUpdate) {
					setStatus('success');

					setItem({
						...res.documentBeforeUpdate,
						iteration: res.documentBeforeUpdate.index
					});
				} else {
					setStatus('error');
				}
			})
			.catch((error) => {
				setStatus('error');

				throw new Error(error.message);
			});
	}, [clearCountdown, id, setStatus]);

	return (
		<Layout h1="Eintrag freischalten">
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Status status={status}>
					{item && (
						<>
							<Alert severity="success">
								Eintrag erfolgreich freigeschaltet. Sie werden in {timeLeft} Sekunden weitergeleitet.
							</Alert>

							<h2>Vorschau</h2>

							{/* <Item item={item} iteration="$route.query.iteration" hideEditButton="true" /> */}
							<Item item={item} showEditButton={false} />
						</>
					)}
				</Status>
			</Grid>
		</Layout>
	);
};

export default Delete;