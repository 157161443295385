import { Grid, Typography } from '@mui/material';
import React from 'react';
import Item from './Item';
import NoItems from './NoItems';

const List = ({ items }) => {
	return (
		<>
			<Grid container rowSpacing={2.5}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Typography variant="h5" component="h2">
						Einträge:
					</Typography>
				</Grid>

				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Grid container rowSpacing={2.5}>
						{!items && (
							<NoItems />
						)}

						{items && items.map((item, index) => (
							<Item item={item} key={`item-${index}`} />
						))}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default List;