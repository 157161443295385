import React from 'react';
import Form from './Form';
import Preview from './Preview';

const FormSection = ({ totalItems }) => {
	// TODO: Load FormField setup from config => useForm hook?
	// FIXME Reset form data on submit
	const defaultFormData = React.useMemo(() => ({
		name: { label: 'Name', name: 'name', error: false, value: '' },
		email: { label: 'E-Mail', name: 'email', error: false, value: '' },
		message: { label: 'Nachricht', name: 'message', error: false, value: '' }
	}), []);
	const [formData, setFormData] = React.useState(defaultFormData);
	const [disabled, setDisabled] = React.useState(true);
	const [notification, setNotification] = React.useState(false);

	const [preview, setPreview] = React.useState(false);

	const handlePreview = React.useCallback(() => {
		setPreview(!preview);
	}, [preview]);

	const handleNotification = React.useCallback(() => {
		setNotification((prevState) => !prevState);
	}, []);

	const handleResetFormData = React.useCallback(() => {
		setFormData(defaultFormData);
	}, [defaultFormData]);

	const notify = React.useMemo(() => {
		return {
			open: notification,
			onClose: handleNotification
		};
	}, [handleNotification, notification]);

	if (preview) {
		return (
			<Preview handlePreview={handlePreview} formData={formData} resetFormData={handleResetFormData} handleNotification={handleNotification} />
		);
	}

	return (
		<Form handlePreview={handlePreview} formData={formData} setFormData={setFormData} disabled={disabled} setDisabled={setDisabled} notification={notify} />
	);
};

export default FormSection;