import React from 'react';

const useInterval = (callback, delay = 0) => {
	const callbackRef = React.useRef(callback);
	const intervalRef = React.useRef(null);

	// Don't schedule if no delay is specified.
	// Note: 0 is a valid value for delay.
	if (!Number(delay)) {
		throw Error('The value given in "delay" needs to be a number.');
	}

	// Remember the latest callback if it changes.
	React.useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	const intervalSet = React.useCallback(() => {
		intervalRef.current = setInterval(() => callbackRef.current(), delay);
	}, [delay]);

	const intervalClear = React.useCallback(() => {
		console.log(1);
		clearInterval(intervalRef.current);
	}, []);

	const intervalReset = React.useCallback(() => {
		intervalClear(intervalRef.current);

		intervalSet();
	}, [intervalClear, intervalSet]);

	React.useEffect(() => {
		// Set up the interval.
		intervalSet();

		// Execute on unmount
		return () => {
			clearInterval(intervalRef.current);
		};
	}, [intervalClear, intervalSet]);

	return {
		intervalClear,
		intervalReset
	}
};

export default useInterval;