import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

const AJAXPagination = ({ total }) => {
	const { locale, pageID: page = 1 } = useParams();
	const [hidePageButton, setHidePapeButton] = React.useState(false);
	const navigate = useNavigate();

	React.useEffect(() => {
		const currentPage = Number(page);

		if (total > 0 && currentPage >= total) {
			setHidePapeButton(true);

			return;
		}

		setHidePapeButton(false);
	}, [page, total]);

	const handleAJAXPageChange = (page) => {
		const nextPage = page + 1;

		if (nextPage === 1) {
			navigate(`/${locale}`);

			return;
		}

		navigate(`/${locale}/page/${nextPage}`);
	};

	if (hidePageButton) {
		return null;
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Button variant="contained" onClick={() => handleAJAXPageChange(Number(page))}>
				Lade Seite {Number(page) + 1}
			</Button>
		</div>
	);
};

export default AJAXPagination;