import React from 'react';
import AJAXPagination from './AJAXPagination';
import StaticPagination from './StaticPagination';
import config from '../../config';

const Pagination = ({ total, ajax = false }) => {
	const totalPages = Math.ceil(total / config.pages.load.maxPerPage);

	if (ajax) {
		return (
			<AJAXPagination total={totalPages} />
		);
	}

	return (
		<StaticPagination total={totalPages} />
	);
};

export default Pagination;