import React from 'react';
import { Alert } from '@mui/material';

const Status = ({
	children,
	status,
	text = {
		loading: 'Eintrag wird geladen',
		error: 'Eintrag nicht gefunden!'
	}
}) => {
	return (
		<>
			{status === 'loading' && (
				<Alert severity="info">
					{text.loading}
				</Alert>
			)}

			{status === 'error' && (
				<Alert severity="error">
					{text.error}
				</Alert>
			)}

			{status === 'success' && children}
		</>
	);
};

export default Status;